import { Injectable, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class ArmazenamentoService {

    constructor(
        private router: Router
    ) { }

    obterItem(nome) {
        if (typeof window !== 'undefined'){
            if (this.obterModalidadeLogin() === 'session') {
                return sessionStorage.getItem(nome)    
            } else {
                return localStorage.getItem(nome)
            }
        }else{
            return null
        }
    }

    definirItem(nome, valor) {
        if (typeof window !== 'undefined'){
            if (this.obterModalidadeLogin() === 'session') {
                sessionStorage.setItem(nome, valor)
            } else {
                localStorage.setItem(nome, valor)
            }
        }
    }

    obterModalidadeLogin() {
        let modalidadeLogin;
        
        if (typeof window !== 'undefined') {
            modalidadeLogin = localStorage.getItem('modalidadeLogin');
    
            if (modalidadeLogin !== 'session' && modalidadeLogin !== 'local') {
                localStorage.removeItem('modalidadeLogin');
                localStorage.removeItem('LRLogU');
                sessionStorage.removeItem('modalidadeLogin');
                sessionStorage.removeItem('LRLogU');
                localStorage.setItem('modalidadeLogin', 'session');
                
                this.router.navigate(['/login']);
            }
        } else {
            return null; // Ou algum valor padrão ou comportamento específico para o lado do servidor
        }
    
        return modalidadeLogin;
    }
    
}
